import React, { useEffect, useState } from 'react';
import { Typography, Slide, Box, Drawer, styled, Button, IconButton, Divider, useTheme, Tooltip, CssBaseline, Toolbar, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { db } from "core/config/firebase";
import { getDocs, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { getCurrentUserId } from "core/api/api";
import { useAuth } from "contexts/AuthContext";
import { ChevronLeft, ChevronRight, BookOutlined, Create, Autorenew, Campaign } from '@mui/icons-material';
import { useChat } from 'contexts/ChatContext';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuItem, Menu } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MailIcon from '@mui/icons-material/Mail';
import CustomInstructions from './components/CustomInstructions';
import CampaignIcon from '@mui/icons-material/Campaign';
import SettingsIcon from '@mui/icons-material/Settings';
import { Edit } from "@mui/icons-material";
import LinearProgress from '@mui/material/LinearProgress';
import KnowledgeBase from '../KnowledgeBase';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { format } from 'date-fns';


import ChatBot from '../ChatBot';
import { logoLight } from 'assets';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));


const HistorySidebar = () => {
  const [docNames, setDocNames] = useState<string[]>([]);
  const { userInfo } = useAuth();
  const uid = getCurrentUserId();
  const theme = useTheme();

  const { chatSessionList, switchChat, initializeNewSession, loadChatHistoryList, createNewChat, firstChatLoading, currentSessionId } = useChat();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [instructionText, setInstructionText] = useState<string>("");
  const [isOpen, setOpen] = useState(false);
  const [chatTitles, setChatTitles] = useState<{ [key: string]: string | null }>({});
  const [toolsAnchorEl, setToolsAnchorEl] = useState<null | HTMLElement>(null);
  const [knowledgeBaseAnchorEl, setKnowledgeBaseAnchorEl] = useState<null | HTMLElement>(null);
  const [knowledgeBaseOpen, setKnowledgeBaseOpen] = useState(false);

  const handleKnowledgeBaseOpen = () => {
    setKnowledgeBaseOpen(true);
  };

  const handleKnowledgeBaseClose = () => {
    setKnowledgeBaseOpen(false);
  };

  const handleToolsClick = (event: React.MouseEvent<HTMLElement>) => {
    setToolsAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setToolsAnchorEl(null);
    setKnowledgeBaseAnchorEl(null);
  };


  // always grab the most recent values from firestore
  const handleDialogOpen = async () => {
    if (!uid) {
      console.error("User ID is not defined");
      return;
    }
    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const latestInstructions = userDoc.data().chat_custom_instructions || "";
        setInstructionText(latestInstructions.replace(/^"|"$/g, ""));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setDialogOpen(true);
  };

  useEffect(() => {
    const fetchChatTitles = async () => {
      if (!uid) return;
  
      const titles: { [key: string]: string | null } = {};
  
      for (const session of chatSessionList) {
        const chatHistoryRef = doc(db, `users/${uid}/chat_history/${session.id}`);
        await getDoc(chatHistoryRef)
          .then((docData) => {
            if (docData.exists()) {
              const messages = docData.data()["messages"] || [];
              const title = messages[1]?.data?.chat_title;
              titles[session.id] = title || null;
            }
          })
          .catch((error) => {
            console.error("Error fetching chat title:", error);
          });
      }
      setChatTitles(titles);
    };
  
    fetchChatTitles();
  }, [uid, chatSessionList, db]);
  

  useEffect(() => {
    // Remove quotes from value
    const userCustomMessage = userInfo?.chat_custom_instructions || "";
    setInstructionText(userCustomMessage.replace(/^"|"$/g, ""));
  }, [userInfo]);

  // set new chat_custom_instructions in firestore
  const setInstructions = async (newInstructions: string) => {
    if (!uid) {
      console.error("User ID is not defined");
      return;
    }

    try {
      const userDocRef = doc(db, "users", uid);
      await updateDoc(userDocRef, {
        chat_custom_instructions: newInstructions,
      });
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSidebarHistoryToggle = () => {
    setOpen(prevState => !prevState);
  };

  const handleSidebarHistoryOpen = () => {
    setOpen(true);
  };

  const handleSidebarHistoryClose = () => {
    setOpen(false);
  };

  const handleInitializeNewSession = async () => {
    setOpen(false);
    await initializeNewSession();
  };

  const handleSwitchChat = async (sessionId: string) => {
    setOpen(false);
    await switchChat(sessionId);
  }

  const drawerWidth = 240;

  useEffect(() => {
    loadChatHistoryList();
  }, [isOpen]);

  const formatTimestamp = (timestamp: string) => {
    const timestampNumber = parseFloat(timestamp);

    if (isNaN(timestampNumber)) {
      // console.log(timestampNumber)
      // console.error(`Invalid timestamp value: ${timestamp}`);
      return 'Invalid Date';
    }

    const date = new Date(timestampNumber);

    return format(date, 'dd MMM yyyy');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={isOpen}
      >
        <DrawerHeader style={{ backgroundColor: '#30242e', display: 'flex', justifyContent: 'space-between' }}>
          <Box
            component="img"
            src={logoLight}
            sx={{ width: "10vw", maxWidth: 70, maxHeight: 70 }}
          />
        </DrawerHeader>
        <Divider />
        <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit>
          <Box sx={{ height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Tooltip title="New Chat" placement="right" >
                <IconButton onClick={createNewChat} >
                  <Create />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close sidebar" placement='right' sx={{ alignSelf: 'flex-end' }}>
                <IconButton onClick={handleSidebarHistoryClose}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon color='error' /> : <ChevronRightIcon />}
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
              <Typography variant="h6" sx={{ paddingTop: 1, paddingBottom: 1 }}>
                Chat History
              </Typography>
              <Box sx={{ flexGrow: 1, overflow: 'auto', width: '100%', maxHeight: '70vh' }}>
                {chatSessionList.length > 0 ? (
                  <List>
                    {chatSessionList.map((historySessionObj: HistorySession, index) => {
                      const isActive = currentSessionId === historySessionObj.id;
                      const chatTitle = chatTitles[historySessionObj.id];
                      const formattedDate = formatTimestamp(historySessionObj.date_created);

                      return chatTitle ? (
                        <ListItem disablePadding key={historySessionObj.id || index}>
                          <ListItemButton
                            onClick={() => handleSwitchChat(historySessionObj.id)}
                            sx={{
                              backgroundColor: isActive
                                ? theme.palette.action.selected
                                : "inherit",
                              "&:hover": {
                                backgroundColor: isActive
                                  ? theme.palette.action.selected
                                  : theme.palette.action.hover,
                              },
                            }}
                          >
                            {firstChatLoading.isLoading && firstChatLoading.index === index ? (
                              <LinearProgress
                                sx={{
                                  width: "100%",
                                  color: theme.palette.primary.main,
                                }}
                              />
                            ) : (
                              <Box>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>
                                  {formattedDate}
                                </Typography>
                                <ListItemText
                                  primary={chatTitle}
                                  primaryTypographyProps={{ fontSize: "14px" }}
                                />
                              </Box>
                            )}
                          </ListItemButton>
                        </ListItem>
                      ) : null;
                    })}
                  </List>
                ) : (
                  <Typography variant="body1" sx={{ ml: 8 }}>No chat history.</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Slide>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
              setDialogOpen(true)
            }}>
              <ListItemIcon>
                <Campaign />
              </ListItemIcon>
              <ListItemText primary={"Custom Instructions"} />
            </ListItemButton>
          </ListItem>
          {/* ))} */}
        </List>
      </Drawer>
      <Main open={isOpen} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          {!isOpen &&
            <Tooltip title="Chat History" placement="bottom">
              <IconButton onClick={handleSidebarHistoryToggle} >
                <BookOutlined />
              </IconButton>
            </Tooltip>
          }
          {!isOpen &&
            <Tooltip title="New Chat" placement="bottom">
              <IconButton onClick={createNewChat} >
                <Create />
              </IconButton>
            </Tooltip>
          }
          {!isOpen &&
            <>
              <Tooltip title="Additional Tools" placement="bottom">
                <IconButton onClick={handleToolsClick}>
                  <MenuIcon />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={toolsAnchorEl}
                open={Boolean(toolsAnchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setDialogOpen(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Campaign />
                  </ListItemIcon>
                  <ListItemText primary="Custom Instructions" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleKnowledgeBaseOpen();
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <LocalLibraryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Knowledge Base" />
                </MenuItem>
              </Menu>
            </>
          }
        </Box>
        {/* <DrawerHeader sx={{backgroundColor: 'black'}}/> */}
        <Box sx={{ width: '85%', alignSelf: 'center' }}>
          <CustomInstructions
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            instructionText={instructionText}
            setInstructionText={setInstructionText}
            setInstructions={setInstructions}
          />
          <KnowledgeBase open={knowledgeBaseOpen} onClose={handleKnowledgeBaseClose} />
          <ChatBot />
        </Box>
      </Main>
    </Box>
  );
};

export default HistorySidebar;