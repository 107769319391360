import {
    collection,
    getDocs,
    getFirestore,
} from 'firebase/firestore';

const db = getFirestore();
export const getChatSessionsFromFirestore = async (userId: string): Promise<HistorySession[]> => {
    if (!userId) {
      throw Error("User ID is not defined");
    }
  
    const docsSnapshot = await getDocs(collection(db, "users", userId, "chat_history")).catch((error) => {
      console.error("Error fetching document names:", error);
      return null; // Return null or an empty array to handle error scenario
    });
  
    if (!docsSnapshot) return []; // If fetching docs failed, return an empty array
  
    const chatList: HistorySession[] = [];
  
    docsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (!data) {
        return;
      }
  
      const lastMessage = data.messages[data.messages.length - 1];
      const timestamp = lastMessage?.data?.additional_kwargs?.timestamp;
      const name1 = data.messages[1]?.data?.chat_title;
  
      if (timestamp && name1) {
        // Push formatted data to chatList
        chatList.push({
          id: data.id,
          name: name1,
          date_created: (timestamp * 1000).toString(),
        });
      } else {
        // Handle cases where timestamp or chat title is missing
        // console.error('Error processing chat data: Missing timestamp or chat title');
        chatList.push({
          id: data.id,
          name: '...',
          date_created: "date" + data.id,
        });
      }
    });
  
    return chatList;
  };
  