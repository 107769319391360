import React, { useEffect, useState } from 'react';
// import { PDFLoader } from './PDFLoader';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    IconButton,
    Typography,
} from '@mui/material';
import { fetchPDF } from 'core/api/getPdfFromFbStorage';
import CloseIcon from '@mui/icons-material/Close';

export const PDFDialog = ({ pdfData, open, handleClose }: any) => {
    const { fileName, page } = pdfData;


    const [pdfUrl, setPdfUrl] = useState<string | null>(null);

    useEffect(() => {
        const returnPDFBlob = async () => {
            try {
                const url = await fetchPDF(fileName);
                if (url) {
                    const fullPdfUrl = page ? `${url}#page=${page}` : url;
                    setPdfUrl(fullPdfUrl);
                }
            } catch (error) {
                console.error('Error fetching PDF:', error);
                setPdfUrl(null);
            }
        };

        if (fileName && open) {
            returnPDFBlob();
        }
    }, [fileName, page, open]);


    const handleDownload = () => {
        if (pdfUrl) {
            // pdfUrl is already a blob URL here
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    return (
        <>
            {(pdfUrl) && (
                <Dialog
                    fullWidth
                    maxWidth="xl"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            minHeight: '95vh',
                        },
                    }}
                >
                    <DialogTitle>PDF Viewer</DialogTitle>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: 25,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent style={{ height: '85vh', overflowY: 'hidden' }}>
                        <iframe
                            src={pdfUrl}
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none'
                            }}
                        ></iframe>
                    </DialogContent>
                    <Button onClick={handleDownload}>Download PDF</Button>

                </Dialog >
            )
            }

        </>
    );
}