import React, { useState, useRef, useEffect } from 'react';
import {
    Container,
    Box,
    TextField,
    Button,
    Typography,
    Link,
    CircularProgress,
} from '@mui/material';
// import { PDFView } from './components/PDFView';
import { PDFDialog } from './components/PDFDialog';
import { Filter } from './components/Filter';
import { useChat } from 'contexts/ChatContext';
// import { log } from 'logrocket';
import Header from '../Header';
import ResponseList from './components/SourceList';
import SourceList from './components/SourceList';
import { marked } from 'marked';


// {entry.darby.file_names && entry.darby.file_names.map((fileName, idx) => (

//     <Typography
//         key={idx}
//         component="span"
//         sx={{ ml: 1, textDecoration: 'underline', cursor: 'pointer', color: 'black' }}
//         onClick={() => handlePdfOpen(fileName, entry.darby.page_numbers[idx])}
//     >
//         {/* [{idx + 1}] {entry.darby.issuance_subjects[idx]} {entry.darby.page_numbers[idx]}
//         <br></br> */}
//         [{idx + 1}] {entry.darby.issuance_subjects[idx]}{fileName} - Page {entry.darby.page_numbers[idx]} 

//         <br></br>
//     </Typography>

// ))}

const ChatBot = (): JSX.Element => {
    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
    const [selectedPdfUrl, setSelectedPdfUrl] = useState({ fileName: '', page: '' });
    const [userInput, setUserInput] = useState<string | null>(null);

    const {
        handleSubmit,
        inputText,
        setInputText,
        isLoading,
        history,
        bottomRef,
    } = useChat();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setUserInput(inputText);
            setInputText('');
            handleSubmit();
        }
    };

    const createMarkUp = (markdown: string): { __html: string } => {
        const htmlContent = marked(markdown);
        if (typeof htmlContent !== 'string') {
            throw new Error("Marked did not return a string. Check the usage.");
        }
        return { __html: htmlContent };
    };



    const handlePdfOpen = (fileName: string, page: string) => {
        console.log('PAGE: ', page);
        setSelectedPdfUrl({ fileName, page });
        setPdfDialogOpen(true);
    };

    const handlePdfClose = () => {
        setPdfDialogOpen(false);
    };


    return (
        <Box sx={{ display: 'flex', height: '75vh' }}>
            {/* <PDFView history={history}/> */}
            <PDFDialog
                pdfData={selectedPdfUrl}
                open={pdfDialogOpen}
                handleClose={handlePdfClose}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 0, width: '100%' }}>
                {/* <Header /> */}
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                    }}
                >
                    {history.map((entry, index) => (
                        <Box key={index} sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>User:</Typography>
                                <Typography variant="body1" sx={{ ml: 1 }}>{entry.user}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Darby:</Typography>
                                <Typography variant="body1" component="span" sx={{ ml: 1 }}>
                                    <span dangerouslySetInnerHTML={createMarkUp(entry.darby.answer)} />
                                    {entry.darby.file_names && entry.darby.file_names.some(fileName => fileName.trim() !== '') && (
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        <br></br>
                                        Sources:
                                    </Typography>
                                )}
                                <SourceList listItemsData={entry.darby} handlePdfOpen={handlePdfOpen} />

                                </Typography>
                            </Box>
                        </Box>
                    ))}

                    {isLoading && (

                        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>User:</Typography>
                                <Typography variant="body1" sx={{ ml: 1 }}>{userInput}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                                <Typography variant="body1" sx={{ ml: 2 }}>
                                    Searching for Relevant DoD Source Material...
                                </Typography>
                            </Box>
                        </Box>


                    )}

                    <Box ref={bottomRef} sx={{ height: isLoading ? '64px' : '0px' }}></Box>

                </Box>

                <Box marginLeft={2}>
                    <Filter />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        p: 2,
                        justifyContent: 'center'
                    }}
                >
                    <TextField
                        label="Type your message"
                        variant="outlined"
                        value={inputText}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        style={{ width: '100%' }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>

        </Box>
    );
}

export default ChatBot;
