import React from 'react';
import { Button, CircularProgress, Typography, Box, Divider } from '@mui/material';
import stripeLogo  from 'assets/stripeLogo.jpg'
import CloseIcon from '@mui/icons-material/Close';

interface ClientViewProps {
    isLoading: boolean;
    doStripeStuff: () => void;
    userData: any;
    subscriptionData: any;
}

const ClientView: React.FC<ClientViewProps> = ({ isLoading, doStripeStuff, userData, subscriptionData }) => (
    <Box mt={1} >
        {/* {isLoading ? (
            <Box sx={{flex: 4, boxShadow: 3, borderRadius: 12, width: '100%'}}>
                <CircularProgress />
            </Box>
        ) : ( */}
            <Box sx={{flex: 1, boxShadow: 3, borderRadius: 12, width: {xs: 325, md: '100%'}, mb: 10}}>
                            <Box sx={{ display: 'flex', p: 0.5, }}>
                                <Typography variant='h4' sx={{m: 1, ml: 2}}>Your plan</Typography>
                            </Box>
                            <Divider />
                            <Box sx={{display: 'flex', flexDirection: {md: 'row', xs: 'column'}, height: '70%', paddingX: 2}}>
                                <Box sx={{flex: 1, pl: 0}}>
                                    <Typography variant='h6'>Name:</Typography>
                                    <Typography variant='body1'>{userData.name}</Typography>
                                    <Typography variant='h6'>Email:</Typography>
                                    <Typography variant='body1'>{userData.email}</Typography>
                                    <Typography variant='h6'>Subscription Status:</Typography>
                                    <Typography variant='body1'>{userData.user_type === 'customer' ? 'Subscribed' : 'Not Subscribed'}</Typography>
                                </Box>
                                {/* <Divider orientation='vertical'/> */}
                                <Box sx={{flex: 1, pl: {md: 2, xs: 0}, mt:{md: 0, xs: 4}}}>
                                    <Typography variant='h5'>Current Subscription Perks:</Typography>
                                    <Box sx={{display:'flex', flexDirection: 'row', marginY: 2}}>
                                        <CloseIcon color='warning'/>
                                        <Typography variant='body1'>Unlimited access to the Darby chatbot - Ask all the questions you want</Typography>
                                    </Box>
                                    <Box sx={{display:'flex', flexDirection: 'row', marginY: 2}}>
                                        <CloseIcon color='warning'/>
                                        <Typography variant='body1'>Chat history - Continue any conversation from a previous session</Typography>
                                    </Box>
                                    <Box sx={{display:'flex', flexDirection: 'row', marginY: 2}}>
                                        <CloseIcon color='warning'/>
                                        <Typography variant='body1'>PDF lookup - Find the exact source text for your queries directly from the PDF</Typography>
                                    </Box>
                                    <Typography variant='body1' mb={2}>Price: $50.00 /month</Typography>
                                    {/* <Typography variant='h6' textAlign={'center'}>Click the Subscribe Button Below to Get Started!</Typography> */}
                                </Box>
                            </Box>
                            <Divider />
                            <Box sx={{display: 'flex', flexDirection: {md: 'row', xs: 'column'} ,alignItems: 'center', justifyContent: 'space-evenly', paddingTop: 0, height:{md: 50, xs: 100}}}>
                                <Button variant="contained" color="primary" onClick={doStripeStuff} sx={{ width: '60%', height: 35 }}>
                                    {isLoading ? <CircularProgress size={24}
                                                    sx={{
                                                        color: 'white',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}/> : `Subscribe`}
                                </Button>
                            </Box>
                        </Box>
    
                   {/* <Button variant="contained" color="primary" onClick={doStripeStuff} sx={{ mb: 2, mx: 1 }}>
                       Subscribe
                    </Button> */}
        {/* )} */}
        {/* <Typography variant="h5" gutterBottom alignSelf={'center'}>
            Subscribe through our partner to use Darby!
        </Typography>
        <Box alignSelf={'center'}>
            <img src={stripeLogo} alt="Stripe" width="150" />
        </Box> */}
    </Box>
);

export default ClientView;